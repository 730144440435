<template>
  <a-spin tip="Processing..." :spinning="spinning">
    <div>
        <CAlert v-if="errorMsgs.length > 0" color="danger">
          <ul v-for="msg in errorMsgs" :key="msg" class="px-0">
            <li>{{msg}}</li>
          </ul>
        </CAlert>

        <CInput
          label="Background of Learners"
          v-model="info.background"
        />
        <CInput
          label="Contact details on the day (Name & Telephone)"
          v-model="info.contact"
        />
        <CInput
          label="Full Address of the Training Venue"
          v-model="info.address"
        />        
        <CInput
          label="Course Timings"
          v-model="info.time"
        />

        <a-radio-group button-style="solid" v-model="info.am" @change="changeAMPM">
          <a-radio-button :value="0">
            All Day
          </a-radio-button>
          <a-radio-button :value="1">
            AM
          </a-radio-button>
          <a-radio-button :value="2">
            PM
          </a-radio-button>
        </a-radio-group>

        <CInput
          class="mt-3"
          label="Training Equipment"
          v-model="info.equipment"
        />

        <CInputCheckbox
          label="Completed"
          :checked="info.completed"
          @update:checked="changeCompleted"
        />

        <CInputCheckbox
          label="Invoiced"
          :checked="info.invoiced"
          @update:checked="changeInvoiced"
        />

        <CInput
          class="mt-3"
          label="Attendees (comma-separated)"
          v-model="info.attendees"
        />

        <CInput
          label="Notes"
          v-model="info.notes"
        />

        <CInput
          label="PO Number"
          v-model="info.po_number"
        />
        
        <div>
          <CButton
            class="mt-5"
            color='primary'
            :block="true"
            @click="save"
          >
            Save
          </CButton>
        </div>
    </div>
  </a-spin>
</template>
<script>  
  import encryptStorage from "../../controllers/encryptStorage.js";
  import HTTPRequest from "../../controllers/httpRequest.js";
  import { mapState } from "vuex";
  export default {
    name: "BookingInformationForm",
    props: {
      bookingData: Object
    },
    computed: {
      ...mapState(["endPoint"])
    },
    data() {
      return {
        errorMsgs: [],
        loggedInUserId: encryptStorage.getItem('loggedInUserId'),
        authHeaders: {
          Authorization: 'Bearer ' + encryptStorage.getItem("accessToken"),
          "Access-Control-Allow-Origin": "*" 
        },
        info: {},
        spinning: false,
      }
    },
    mounted(){
      this.info = {...this.bookingData }
    },
    methods: {
      changeAMPM(e){
        this.info.am = Number(e.target.value);
      },
      changeCompleted(value){
        this.info.completed = value;
      },
      changeInvoiced(value){
        this.info.invoiced = value;
      },
      validate(){
        return true;
      },
      async save(){
        this.spinning = true;
        if (this.validate()) {
          var response = await HTTPRequest.postRequest(
            this.endPoint, 
            "/api/v1/bookings/booking_information",
            null, 
            {booking_data: this.info}
          );
          if (response.error) {
            this.errorMsgs.push(response.error);
          } else {
            this.$emit('sendToBookingForm', response.id);
          } 
        }
        this.spinning = false;
      }
    }
  };
</script>
