<template>
  <a-spin tip="Processing..." :spinning="spinning">
    <CCardBody class="p-0">
      <a-comment v-for="f in feedbacks" :key="f.id">
        <a slot="author"> {{ f.user_full_name }}</a>
        <p slot="content">
          {{ f.comment }}
        </p>
        <a-tooltip slot="datetime">
          <span>{{ f.created_at_date }} {{ f.created_at_time }}</span>
          <br>
          <span>{{ f.feedback_type }} | {{ f.is_action }}</span>
        </a-tooltip>
      </a-comment>

      Feedback
      <textarea class="form-control mt-2"
        placeholder="Feedback"
        v-model="feedback"
      >
      </textarea>

       <div>
          <CButton
            class="mt-2"
            color='primary'
            :block="true"
            @click="submitFeedback"
          >
            Submit
          </CButton>
        </div>
    </CCardBody>
  </a-spin>
</template>
<script>  
  import encryptStorage from "../../controllers/encryptStorage.js";
  import HTTPRequest from "../../controllers/httpRequest.js";
  import { mapState } from "vuex";

  export default {
    name: "Feedback",
    props: {
      bookingId: Number
    },
    computed: {
      ...mapState(["endPoint"])
    },
    data() {
      return {
        errorMsgs: [],
        loggedInUserId: encryptStorage.getItem('loggedInUserId'),
        authHeaders: {
          Authorization: 'Bearer ' + encryptStorage.getItem("accessToken"),
          "Access-Control-Allow-Origin": "*" 
        },
        spinning: false,
        feedbacks: [],
        feedback: ''
      }
    },
    mounted (){
      this.retrieveFeedback();
    },
    methods: {
      async retrieveFeedback(){
        this.spinning = true;
        let response = await HTTPRequest.getRequest(this.endPoint, "/api/v1/feedbacks/all?booking_id="+this.bookingId, null, null);
        this.feedbacks = response.feedbacks
        this.spinning = false;
      },
      async submitFeedback(){
            let params = { booking_id: this.bookingId, user_id: this.loggedInUserId, comment: this.feedback, feedback_type: "G", is_action: false}
            let response = await HTTPRequest.postRequest(this.endPoint,"/api/v1/feedbacks/create", null, params);    
            this.retrieveFeedback();
            this.feedback = '';
            this.feedbacks = [];
        },
    }
  };
</script>


<style scoped>
.ant-comment-inner{
  padding: 0px;
}
</style>