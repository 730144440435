<template>
  <a-spin tip="Processing..." :spinning="spinning">
    <div>
        <CAlert v-if="errorMsgs.length > 0" color="danger">
          <ul v-for="msg in errorMsgs" :key="msg" class="px-0">
            <li>{{msg}}</li>
          </ul>
        </CAlert>

        <CInput
          label="Client Firstname"
          v-model="client.name"
        />
        <CInput
          label="Client Surname"
          v-model="client.surname"
        />
        <CInput
          label="Telephone Number"
          v-model="client.tel"
        />
        <CInput
          label="Email Address"
          v-model="client.email"
        />
        
        <CInput
          label="Company Name"
          v-model="client.company_name"
          @selected="selectCompany"
          disabled="true"
        />

        <autocomplete
          ref="autocomplete"
          placeholder="Search Company"
          :source="findCompaniesEndpoint"
          input-class="form-control"
          results-property="data"
          :results-display="autocompleteFormatDisplayCompany"
          :request-headers="authHeaders"
          @selected="selectCompany"
        >
        </autocomplete>
        
        <div>
          <CButton
            class="mt-5"
            color='primary'
            :block="true"
            @click="createClient"
          >
            Create Client
          </CButton>
        </div>
    </div>
  </a-spin>
</template>
<script>  

  import encryptStorage from "../../controllers/encryptStorage.js";
  import HTTPRequest from "../../controllers/httpRequest.js";
  import { mapState } from "vuex";
  import Autocomplete from 'vuejs-auto-complete';
  import _isEmpty from 'lodash/isEmpty';

  export default {
    name: "ClientForm",
    components: { Autocomplete },
    computed: {
      ...mapState(["endPoint"])
    },
    data() {
      return {
        errorMsgs: [],
        loggedInUserId: encryptStorage.getItem('loggedInUserId'),
        authHeaders: {
          Authorization: 'Bearer ' + encryptStorage.getItem("accessToken"),
          "Access-Control-Allow-Origin": "*" 
        },
        client: {},
        spinning: false,
      }
    },
    methods: {
      validate(){
        let re = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        if (_isEmpty(this.client)) {
          this.errorMsgs.push("Please input or select a client");
          return false;
        } else if (!re.test(this.client.email)) {
          this.errorMsgs.push("Please check your email input");
          return false;
        } else if (!this.client.tel) {
          this.errorMsgs.push("Please check your telephone input");
          return false;
        } else if (!this.client.name) {
          this.errorMsgs.push("Please check your client name");
          return false;
        } else if (!this.client.surname) {
          this.errorMsgs.push("Please check your client surname");
          return false;
        } else if (!this.client.company_name) {
          this.errorMsgs.push("Please check your company name");
          return false;
        } else if (!this.client.company_id) {
          this.errorMsgs.push("Please check your company name");
          return false;
        } else {
          return true;
        }
      },
      async createClient(){
        this.spinning = true;
        if (this.validate()) {
          var response = await HTTPRequest.postRequest(
            this.endPoint, 
            "/api/v1/users/create_client",
            null, 
            {client: this.client}
          );
          this.$emit('sendToBookingForm', response.client);
        }
        this.spinning = false;
      },
      findCompaniesEndpoint(searchText) {
        return this.endPoint + "/api/v1/bookings/find_companies?name=" + searchText;
      },
      autocompleteFormatDisplayCompany (result) {
        return result.name;
      },
      selectCompany (group) {
        if (_isEmpty(this.client)) {
          this.client = {company_name: group.selectedObject.name};
          this.client.company_id = group.selectedObject.id;
        } else {
          this.client = { ...this.client };
          this.client.company_name = group.selectedObject.name;
          this.client.company_id = group.selectedObject.id;
        }
        
        this.$refs.autocomplete.clear();
      },
    }
  };
</script>
