
<template>
  <div :key="componentKey">
    <a-spin tip="Processing..." :spinning="spinning">
      <a-drawer
        title="Create a Client"
        placement="right"
        :visible="drawerVisible"
        @close="closeDrawer"
        :zIndex=99999
        :width='350'
      >
        <client-form :key="clientDrawerKey" @sendToBookingForm="receivedFromClientForm"></client-form>
      </a-drawer>

      <a-drawer
        title="Booking Information"
        placement="right"
        :visible="bookingInfoDrawerVisible"
        @close="closeDrawer"
        :zIndex=99999
        :width='350'
      >
        <booking-information-form :bookingData="bookingData" @sendToBookingForm="receivedFromBookingInformationForm" :key="bookingInfoDrawerKey"></booking-information-form>
      </a-drawer>

      <a-drawer
        title="Delegates List"
        placement="right"
        :visible="delegatesDrawerVisible"
        @close="closeDrawer"
        :zIndex=99999
        :width='1500'
      >
        <delegates :bookingId="bookingId"  :key="delegatesDrawerKey"></delegates>
      </a-drawer>

      <a-drawer
        title="Feedback"
        placement="right"
        :visible="feedbackDrawerVisible"
        @close="closeDrawer"
        :zIndex=99999
        :width='350'
      >
        <feedback :bookingId="bookingId"  :key="feedbackDrawerKey"></feedback>
      </a-drawer>

      <a-drawer
        title="Tentative"
        placement="right"
        :visible="tentativeDrawerVisible"
        @close="closeDrawer"
        :zIndex=99999
        :width='350'
      >
        <tentative :tentativeAvailableId="tentativeAvailableId" :key="tentativeDrawerKey"></tentative>
      </a-drawer>

      <CToaster :autohide="3000">
        <CToast
          :show="showSuccessBookingToaster"
          color="success"
          header="Notification"
        >
          {{ successToasterMessage }}
        </CToast>
      </CToaster>

      <CToaster>
        <CToast 
          :show="(errorMsgs.length > 0 && showBookModal)"
          color="danger"
          header="Error"
        >
          <ul class='p-2 m-0' v-for="msg in errorMsgs" :key="msg">
            <li>{{msg}}</li>
          </ul>
        </CToast>
      </CToaster>

      <CModal
        title="Confirmation"
        color="info"
        size="sm"
        :centered="true"
        :show.sync="showBookModal"
      >
        <span>Do you want to confirm this booking?
        <hr>
          <CInputCheckbox
            label="Send emails to client and trainers?"
            :checked="sendEmailsOnBooking"
            @update:checked="checkSendEmailsOnBooking"
          />
        </span>
        <template #footer>
          <CButton color="danger" @click="modalCancel">Cancel</CButton>
          <CButton color="success" @click="categorizeBooking('book')">Yes</CButton>
        </template>
        
      </CModal>

      <CModal
        title="Reject"
        color="danger"
        size="lg"
        :centered="true"
        :show.sync="showRejectModal"
      >
        <CAlert v-if="errorMsgs.length > 0" color="danger">
          <strong>Error: </strong>
          <ul v-for="msg in errorMsgs" :key="msg">
            <li>{{msg}}</li>
          </ul>
        </CAlert>

        <CCol sm="12">
          <CSelect
            label="Reject Reason"
            :options="rejectReasonOptions"
            :value="rejectReasonSelect"
            @update:value="selectRejectReason"
          />
        </CCol>
        <CCol sm="12">
          <CInput
              v-model="rejectReason"
          />
        </CCol>
        <template #footer>
          <CButton color="danger" @click="modalCancel">Cancel</CButton>
          <CButton color="success" @click="reject">Reject</CButton>
        </template>
      </CModal>

      <CModal
        title="Cancel"
        color="danger"
        size="lg"
        :centered="true"
        :show.sync="showCancelModal"
      >
        <CAlert v-if="errorMsgs.length > 0" color="danger">
          <strong>Error: </strong>
          <ul v-for="msg in errorMsgs" :key="msg">
            <li>{{msg}}</li>
          </ul>
        </CAlert>

        <CCol sm="12">
          <CInput
              label="Reason"
              v-model="rejectReason"
          />
        </CCol>
        <template #footer>
          <CButton color="success" @click="modalCancel">Exit</CButton>
          <CButton color="danger" @click="cancel">Cancel Booking</CButton>
        </template>
      </CModal>
      <CRow>
        <CCol md="12" v-if="status == 'Booked'">
          <CCard>
            <CCardHeader> 
              <slot name="header">
                <CIcon name="cil-calendar-check"/> Booking Information
                <div class="card-header-actions">
                  <router-link  to="/bookings">
                    <CButton
                      size="sm"
                      color="danger"
                      variant="ghost"
                    > Back to Bookings
                    </CButton>
                  </router-link>
                </div>

              </slot>
            </CCardHeader>

            <CCardBody>
              <a-collapse>
                <a-collapse-panel v-for="b in bookingInformation" v-bind:key="b.id" :header="b.course_title + ' : ' + b.date_formatted + ' : ' + b.trainer_name">
                  <a-descriptions :column="1" size="small" bordered>
                    <a-descriptions-item label="Date">{{b.date_formatted}}</a-descriptions-item>
                    <a-descriptions-item label="Booked On">{{b.created_date_formatted}}</a-descriptions-item>
                    <a-descriptions-item label="Booked By">{{b.booked_by_name}}</a-descriptions-item>
                    <a-descriptions-item label="Client">{{b.company_name}}</a-descriptions-item>
                    <a-descriptions-item label="Trainer">{{b.trainer_name}}</a-descriptions-item>
                    <a-descriptions-item label="Course">{{b.course_title}}</a-descriptions-item>
                    <a-descriptions-item label="AM/PM" v-if="b.am == 0">All Day</a-descriptions-item>
                    <a-descriptions-item label="AM/PM" v-if="b.am == 1">AM</a-descriptions-item>
                    <a-descriptions-item label="AM/PM" v-if="b.am == 2">PM</a-descriptions-item>
                    <a-descriptions-item label="Background of Learners"><span v-html="b.background"/></a-descriptions-item>
                    <a-descriptions-item label="Contact details on the day">{{b.contact}}</a-descriptions-item>
                    <a-descriptions-item label="Full address of the training venue">{{b.address}}</a-descriptions-item>
                    <a-descriptions-item label="Course Timings"><span v-html="b.time"/></a-descriptions-item>
                    <a-descriptions-item label="Training equipment required"><span v-html="b.equipment"/></a-descriptions-item>
                    <a-descriptions-item label="Attendees">{{b.attendees}}</a-descriptions-item>
                    <a-descriptions-item label="Completed">{{b.completed ? "Yes": "No"}}</a-descriptions-item>
                    <a-descriptions-item label="Invoiced">{{b.invoiced ? "Yes": "No"}}</a-descriptions-item>
                    <a-descriptions-item label="Notes"><span v-html="b.notes"/></a-descriptions-item>
                    <a-descriptions-item label="PO#">{{b.po_number}}</a-descriptions-item>
                    <a-descriptions-item label="Action">
                      <CButton color="primary" size="sm" variant="outline" @click="processBookingInfo(b)"> Edit Booking Info </CButton>
                      <CButton class="ml-2" color="primary" variant="outline" size="sm" @click="showDelegates(b.id)"> Delegates </CButton>
                      <CButton class="ml-2" color="primary" variant="outline" size="sm" @click="showFeedback(b.id)"> Feedback </CButton>
                    </a-descriptions-item>
                  </a-descriptions>
                </a-collapse-panel>
              </a-collapse>
            </CCardBody>

            <CCardFooter>
              <CButton
                v-if="((this.routeParams.id > 0) && (loggedInUserRole == 'admin') && (status == 'Booked'))"
                color="success"
                @click="send_confirmation_emails"
                style="margin-left: 8px"
                >Send Confirmation Emails</CButton
              >
            </CCardFooter>
          </CCard>
        </CCol>

        <CCol v-else md="12">
          <CCard>
            <CCardHeader>
              <slot name="header">
                <CIcon name="cil-book"/> Booking Form
                <CBadge :color="getBadge(status)">{{status}}</CBadge>
                <i class="small" v-if='rejectReason'> {{ 'Reason: ' + rejectReason }} </i>
                <div class="card-header-actions">
                  <router-link  to="/bookings">
                    <CButton
                      size="sm"
                      color="danger"
                      variant="ghost"

                    > Back to Bookings
                    </CButton>
                  </router-link>
                </div>
              </slot>
            </CCardHeader>

            <CCardBody>
              <CRow>
                <CCol class="d-md-down-none col-auto">
                  <a-steps
                    progress-dot
                    :current="currentStep"
                    size="small"
                    direction="vertical"
                    @change="onStepClick"
                  >
                    <a-step
                      v-for="item in steps"
                      :key="item.title"
                      :title="item.title"
                      
                    >
                      <span v-if="item.title=='Preferred Dates'" slot="description"> 
                        <template v-for="tag in preferredDates">
                          <a-tag
                            :key="tag"
                            :closable="false"
                          >
                            {{ moment(tag).format("DD-MMMYYYY") }}
                          </a-tag>
                        </template>
                      </span>
                      <span v-else-if="item.title=='Available Dates'" slot="description"> 
                          <CListGroup>
                            <CListGroupItem class="p-1" v-for="d in availableDates.filter(r => checkedOfferedDates.includes(String(r.id)))" :key="d.id">
                              {{ d.name + " " + d.surname + " : " + d.formatted_date }}
                            </CListGroupItem>
                          </CListGroup>
                      </span>
                      <span v-else slot="description"> {{ stepDescription(item) }}</span>
                    </a-step>
                  </a-steps>
                </CCol>

                <CCol>
                  <CAlert 
                    v-if="errorMsgs.length > 0" 
                    color="danger"
                  >
                    <strong>Error: </strong>
                    <ul v-for="msg in errorMsgs" :key="msg">
                      <li>{{msg}}</li>
                    </ul>
                  </CAlert>
                  <CCard>
                    <CCardHeader>
                      <strong v-if="currentStep == 1">
                        <CButton v-if="(status != 'Booked')" color="success" class="float-right mr-2" size="sm" @click="openDrawer('client')"> Create new client </CButton>
                        <CButton
                          v-if="(client.id > 0) && (status != 'Booked')"
                          size="sm"
                          color="danger"
                          @click="clearClient"
                          class="float-right mr-2"
                        > Clear Client
                        </CButton>
                      </strong>
                      <strong v-if="currentStep == 4">
                        Preferred Training Delivery Date
                      </strong>
                      <strong v-else-if="currentStep == 5">
                        Trainers Availability

                        <CDropdown
                          color="info"
                          toggler-text="Sort Trainer By" 
                          size="sm"
                          class="float-right"
                          v-if="status != 'Booked'"
                        >
                          <CDropdownItem @click="sortTrainer('distance')">Distance</CDropdownItem>
                          <CDropdownItem @click="sortTrainer('booking_count')">Booking Count</CDropdownItem>
                          <CDropdownItem @click="sortTrainer('rate')">Full Rate</CDropdownItem>
                          <CDropdownItem @click="sortTrainer('remote_rate')">Remote Rate</CDropdownItem>
                          <CDropdownItem @click="sortTrainer('vaccination_status')">Vaccination Status</CDropdownItem>
                        </CDropdown>

                        <a-month-picker class="float-right mr-2" placeholder="Filter by month" @change="onMonthFilterChange" />
                        
                      </strong>
                      <strong v-else>{{ steps[currentStep].title }}</strong>
                    </CCardHeader>
                    <CCardBody v-if="currentStep == 0">
                      <CRow>
                        <CCol sm="12" style="margin-bottom: 8px">
                          Date of Enquiry
                        </CCol>
                        <CCol sm="12">
                          <a-date-picker
                            :disabled-date="disabledDate"
                            v-model="bookingDate"
                            :format="dateFormat"
                            :disabled="viewOnly"
                            :allowClear="false"
                          ></a-date-picker>
                        </CCol>
                      </CRow>
                    </CCardBody>
                    <CCardBody v-if="currentStep == 1">
                      <CRow>
                        <CCol sm="12">
                          <a-auto-complete
                            :data-source="clients"
                            style="width: 100%"
                            placeholder="Search Client"
                            @select="onClientSelect"
                            @search="onClientSearch"
                          >
                          <template slot="dataSource">
                              <a-select-option v-for="client in clients" :key="client.full_name" :value="String(client.id)">
                                  {{ client.company_name }} [ {{ client.name }} {{ client.surname }}]
                              </a-select-option>
                          </template>
                          <a-input>
                            <a-icon v-if="!clientSearching" class="search-custom-icon"  slot="prefix" type="search" />
                            <a-icon v-else class="search-custom-icon"  slot="prefix" type="loading" />
                          </a-input>
                      </a-auto-complete>

                        </CCol>
                        
                        <CCol sm="12" v-if='client.id > 0' class="mt-2">
                          <a-descriptions size="small" bordered>
                            <a-descriptions-item v-if='client.name' label="Name" :span="2">
                              {{ (client.name || '' ) + ' ' + (client.surname || '' ) }}
                            </a-descriptions-item>

                            <a-descriptions-item v-if='client.company_name' label="Company">
                              {{ client.company_name }}
                            </a-descriptions-item>
                            <a-descriptions-item v-if='client.email' label="Email" :span="2">
                              {{ client.email }}
                            </a-descriptions-item>
                            <a-descriptions-item v-if='client.tel' label="Telephone">
                              {{ client.tel }}
                            </a-descriptions-item>
                          </a-descriptions>
                        </CCol>

                        <CCol sm="12" v-if='client.id > 0' class="mt-2">
                          <template v-for="(tag) in additionalEmail">
                            <a-tooltip v-if="tag.length > 20" :key="tag" :title="tag">
                              <a-tag :key="tag" :closable="true" @close="() => additionalEmailHandleClose(tag)">
                                {{ `${tag.slice(0, 20)}...` }}
                              </a-tag>
                            </a-tooltip>
                            <a-tag v-else :key="tag" :closable="true" @close="() => additionalEmailHandleClose(tag)">
                              {{ tag }}
                            </a-tag>
                          </template>
                          <a-input
                            v-if="additionalEmailVisible"
                            ref="input"
                            type="text"
                            size="small"
                            :style="{ width: '100px' }"
                            :value="additionalEmailValue"
                            @change="additionalEmailHandleInputChange"
                            @blur="additionalEmailHandleInputConfirm"
                            @keyup.enter="additionalEmailHandleInputConfirm"
                          />
                          <a-tag v-else style="background: #fff; borderStyle: dashed;" @click="additionalEmailShowInput">
                            + Additional Email
                          </a-tag>
                        </CCol>

                        <CCol v-if='client.id > 0' sm="12">
                          <a-collapse class="mt-3">
                            <a-collapse-panel header="Requestor">

                              <CCol sm="12">
                                <autocomplete
                                  style="margin-bottom: 8px"
                                  ref="autocomplete"
                                  placeholder="Search Requestor"
                                  :source="findRequestorsEndpoint"
                                  input-class="form-control"
                                  results-property="data"
                                  :results-display="autocompleteFormatDisplayRequestor"
                                  :request-headers="authHeaders"
                                  @selected="selectRequestor"
                                  :disableInput="viewOnly"
                                >
                                </autocomplete>
                              </CCol>
                              <CCol sm="12">
                                <CInput
                                  label="Firstname"
                                  v-model="requestor.firstname"
                                  :disabled="viewOnly"
                                />
                              </CCol>
                              <CCol sm="12">
                                <CInput
                                  label="Surname"
                                  v-model="requestor.surname"
                                  :disabled="viewOnly"
                                />
                              </CCol>
                              <CCol sm="12">
                                <CInput
                                  label="Telephone Number"
                                  v-model="requestor.tel"
                                  :disabled="viewOnly"
                                />
                              </CCol>
                              <CCol sm="12">
                                <CInput
                                  label="Email Address"
                                  v-model="requestor.email"
                                  :disabled="viewOnly"
                                />
                              </CCol>
                              <CCol sm="12">
                                <CButton
                                  v-if="(status != 'Booked')"
                                  size="sm"
                                  color="danger"
                                  @click="clearRequestor"
                                > Clear Requestor
                                </CButton>
                              </CCol>
                            </a-collapse-panel>
                          </a-collapse>
                        </CCol>

                      </CRow>
                    </CCardBody>
                    <CCardBody v-if="currentStep == 2">
                      <CRow class="mb-3 col-sm-12">
                        <a-radio-group :disabled="(status == 'Booked')" v-model="courseScheduleMode" @change="onChangeCourseScheduleMode" button-style="solid">
                          <a-radio-button value="AD">
                            All Day
                          </a-radio-button>
                          <a-radio-button value="AP">
                            AM/PM
                          </a-radio-button>
                        </a-radio-group>
                      </CRow>
                      <CRow class="col-sm-12 mb-3">
                        <a-collapse class="col-sm-12">
                          <a-collapse-panel key="1" :header=" (courseScheduleMode=='AD') ? 'All Day' : 'AM' ">
                            <CCol sm="12">
                              <autocomplete
                                ref="autocomplete"
                                placeholder="Search Course"
                                :source="findCoursesEndpoint"
                                input-class="form-control"
                                results-property="data"
                                :results-display="autocompleteFormatDisplayCourse"
                                :request-headers="authHeaders"
                                @selected="selectCourse"
                                :disableInput="viewOnly"
                              >
                              </autocomplete>
                            </CCol>

                            <CCol sm="12" style="margin-top: 8px">
                              <CInput
                                label="Course Title"
                                placeholder="Please select a course"
                                :disabled="true"
                                v-model="course.title"
                              />
                            </CCol>

                            <CCol sm="12">
                              <CInput
                                label="Course Timings"
                                v-model="course.course_timings"
                                @update:value='courseTimingChange'
                                :disabled="viewOnly"
                              />
                            </CCol>
                          </a-collapse-panel>
                          <a-collapse-panel key="2" header="PM" v-if='courseScheduleMode=="AP"'>
                            <CCol sm="12">
                              <autocomplete
                                ref="autocomplete"
                                placeholder="Search Course"
                                :source="findCoursesEndpoint"
                                input-class="form-control"
                                results-property="data"
                                :results-display="autocompleteFormatDisplayCourse"
                                :request-headers="authHeaders"
                                @selected="selectCourse2"
                                :disableInput="viewOnly"
                              >
                              </autocomplete>
                            </CCol>

                            <CCol sm="12" style="margin-top: 8px">
                              <CInput
                                label="Course Title"
                                placeholder="Please select a course"
                                :disabled="true"
                                v-model="course2.title"
                              />
                            </CCol>

                            <CCol>
                              <CInput
                                label="Course Timings"
                                v-model="course2.course_timings"
                                :disabled="viewOnly"
                                @update:value='courseTimingChange'
                              />
                            </CCol>
                          </a-collapse-panel>
                        </a-collapse>
                      </CRow>
                      
                      <CRow class="col-sm-12">
                        <CSelect
                          label="Course Type"
                          :options="courseTypeOptions"
                          :value="courseType"
                          @update:value="selectCourseType"
                          :disabled="viewOnly"
                        />
                        <CInput
                          class="pl-3"
                          style="width: 50%;"
                          label="Non Standard - Reason"
                          placeholder="Please enter a reason"
                          v-model="nonStandardReason"
                          v-if="courseType=='Non-Standard'"
                          :disabled="viewOnly"
                        />
                      </CRow>


                      <CRow class="col-sm-12">
                        <CInputCheckbox
                          :disabled="viewOnly"
                          label="Remote Faciltated Training"
                          :checked="isRemoteFaciltated"
                          @update:checked="checkRemoteFacilitated"
                        />
                      </CRow>
                      <CRow v-if="!isRemoteFaciltated" class="col-sm-12">
                        <CInputCheckbox
                          label="Risk Assessment and Documents Received"
                          :disabled="viewOnly"
                          :checked="receivedRiskAssessmentDocuments"
                          @update:checked="checkReceivedRiskAssessmentDocuments"
                        />
                      </CRow>
                      <CRow class="col-sm-12">
                        <CInputCheckbox
                          label="Policies Received"
                          :disabled="viewOnly"
                          :checked="receivedDocumentPolicies"
                          @update:checked="checkReceivedDocumentPolicies"
                        />
                      </CRow>
                    </CCardBody>
                    <CCardBody v-if="currentStep == 3">
                      <CRow>
                        <CCol sm="12" style="margin-bottom: 8px">
                          <vue-google-autocomplete
                              ref="googleAddress"
                              id="map"
                              classname="form-control"
                              placeholder="Search venue"
                              v-on:placechanged="getAddressData"
                              :types="['(regions)', 'establishment', 'address', '(cities)']"
                              country="gb"
                          >
                          </vue-google-autocomplete>
                        </CCol>
                        <CCol sm="12">
                          <CInput
                            placeholder="Venue"
                            :disabled="viewOnly"
                            v-model="venue"
                          />
                        </CCol>
                        <CCol sm="12">
                          <CButton size="sm" color="danger" @click="clearVenue">Clear Venue</CButton>
                        </CCol>
                      </CRow>
                    </CCardBody>
                    <CCardBody v-if="currentStep == 4">
                      <CRow>
                        <CCol sm="12" style="margin-bottom: 8px">
                          Select preferred date(s)
                        </CCol>
                        <CCol sm="12" style="margin-bottom: 8px">
                          <a-date-picker
                            :format="dateFormat"
                            :disabled="viewOnly"
                            @change="onPreferredDateSelect"
                          >
                            <template  slot="renderExtraFooter">
                              <span style="color: #f9b115">Weekend</span>
                              <span style="color: #e55353" class="ml-2">Holiday</span>
                            </template>

                            <template slot="dateRender" slot-scope="current">
                              <div class="ant-calendar-date" :style="getDateStyle(current)">
                                {{ current.date() }}
                              </div>
                            </template>
                          </a-date-picker>
                        </CCol>
                      </CRow>
                      <template v-for="tag in preferredDates">
                        <a-tag
                          color="blue"
                          :key="tag"
                          :closable="!viewOnly"
                          @close="() => handleClose(tag)"
                        >
                          {{ moment(tag).format("DD-MMM-YYYY") }}
                        </a-tag>
                      </template>
                    </CCardBody>
                    <CCardBody v-if="currentStep == 5">             
                      <a-spin 
                        class='my-3 d-flex justify-content-center' 
                        v-if='spinning' 
                        tip="Fetching trainer and schedule list...">
                      </a-spin>
                      <a-collapse v-else-if="currentTrainers" @change="collapsePanelChange">
                        <a-collapse-panel :key="String(trainer.id)" :header="trainer.name + ' ' + trainer.surname" v-for="trainer in currentTrainers">
                          <span slot="extra">
                            
                            <a-tooltip>
                              <template slot="title">
                                <p v-if='trainer.medical_exemption'>Medical Exempted: {{ trainer.medical_exemption ? "Yes" : "No" }}</p>

                                <p v-if='trainer.other_restrictions'>Other Restrictions: {{ trainer.other_restrictions }}</p>

                                <p v-if='trainer.knights_other_restrictions'>Knights - Other Restriction: {{ trainer.knights_other_restrictions }}</p>
                              </template>
                              <CIcon name="cil-stream"/> Trainer Restrictions
                            </a-tooltip>

                            <template v-if="selectedSortTrainer=='distance'"> <CIcon name="cil-location-pin"> </CIcon> {{ trainer.distance_away_from_venue.toFixed(2) }} miles away </template>
                            <template v-if="selectedSortTrainer=='booking_count'"> <CIcon name="cil-book"> </CIcon> {{ trainer.booking_count }} booking count  </template>
                            <template v-if="selectedSortTrainer=='vaccination_status'"> <CIcon name="cil-medical-cross"> </CIcon> {{ trainer.is_vaccinated ? "Vaccinated" : "Not Vaccinated" }}  </template>
                            <template v-if="selectedSortTrainer=='rate'"> <CIcon name="cil-money"> </CIcon> Full Rate {{ trainer.full_day_rate }}  </template>
                            <template v-if="selectedSortTrainer=='remote_rate'"> <CIcon name="cil-money"> </CIcon> Remote Rate {{ trainer.half_day_rate }}  </template>
                          </span>
                          

                          <span class='d-md-down-none float-right font-italic'> Availability last updated on {{trainer.availability_last_updated }}</span>
                          
                          <CInputCheckbox
                              v-for="(option) in availableDates.filter(r => (r.user_id == trainer.id) && filterTrainerDates(r.date))"
                              :key="option.id"
                              :value="option.id"
                              :name="option.date"
                              :checked="checkedOfferedDates.includes(option.id.toString())"
                              :disabled="viewOnly ? viewOnly : ((option.date_type == 'provisionally_booked') && !originalCheckedOfferedDates.includes(option.id.toString()))"
                              @update:checked="checkOfferedDate"
                            >
                              <template slot="label">
                                  <span v-if="preferredDates.includes(moment(new Date(option.formatted_date)).format('DD-MMM-YYYY'))" style="color: #f9b115"> {{ option.formatted_date }} </span>
                                  <span v-else> {{ option.formatted_date }} </span>
                              </template>              

                              <template slot="description">
                                <i style="color: #e55353">
                                  {{ option.date_type == 'provisionally_booked' ? '- [Provisional Booking] ' + option.selected_as_draft_by.join(", ") : "" }}
                                </i>
                              </template>
                          </CInputCheckbox>
                          <p v-if="availableDates.filter(r => (r.user_id == trainer.id) && filterTrainerDates(r.date)).length < 1">
                            No Availability
                          </p>
                        </a-collapse-panel>
                      </a-collapse>
                      <div v-else class="d-flex justify-content-center">
                        No Available Trainers
                      </div>
                      <CCard class="mt-4" v-if="checkedOfferedDates.length > 0 && status != 'Booked'">
                        <CCardHeader>
                          Selected trainers and schedule
                        </CCardHeader>
                        <CCardBody>
                          <a-collapse>
                          
                            <a-collapse-panel :key="String(trainer.id)" :header='trainer.name + " " + trainer.surname + " : " + trainer.formatted_date' v-for="trainer in availableDates.filter(r => checkedOfferedDates.includes(String(r.id)))">
                              <span slot="extra">

                                <CButton size="sm" color="primary" class="ml-2" :href="trainer.legacy_link" target="_blank">{{trainer.name + " " + trainer.surname}}</CButton>                           
                                <CBadge v-if="trainer.accepted == 'A' && status == 'Provisional Booking'" color="success">Accepted</CBadge>
                                <CBadge v-if="trainer.accepted == 'D' && status == 'Provisional Booking'" color="danger">Declined</CBadge>
                                <CBadge v-if="trainer.accepted == 'T' && status == 'Provisional Booking'" color="warning">Tentative</CBadge>
                                <CButton v-if="status == 'Draft'" size="sm" color="primary" class="ml-2" @click="splitDraft(trainer.id, trainer.formatted_date)">Split Draft</CButton>
                                <CButton v-if="status == 'Draft'" size="sm" color="danger" class="ml-2" @click="removeDate(trainer.id)">Remove Date</CButton>
                              </span>
                              <CRow>
                                <CCol sm="12" class="mb-3">
                                  <CButton
                                    color="info"
                                    size="sm"
                                    @click="tentativeMessages(trainer.id)"
                                    >Tentative Messages</CButton
                                  >
                                </CCol>
                                <CCol sm="12" class="mb-3" v-if="status == 'Provisional Booking'">
                                  <CButton
                                    color="warning"
                                    size="sm"
                                    @click="resetAcceptedStatus(trainer)"
                                    >Reset Accepted Status for Booking Confirmation</CButton
                                  >
                                </CCol>
                              
                                <CCol sm="6">
                                  <CInput
                                    label="Rate"
                                    :disabled="viewOnly"
                                    v-model="trainer.full_day_rate"
                                  />
                                </CCol>
                                <CCol sm="6">
                                  <a-descriptions size="small" bordered class="pb-1" :column=1>        
                                    <a-descriptions-item 
                                      v-for='(value, key) in {"Full Rate": "full_day_rate", "Remote Rate": "half_day_rate"}'
                                      :label="key" :key="key"
                                    >
                                      {{trainer.user[value] || ""}}
                                    </a-descriptions-item>
                                  </a-descriptions>
                                </CCol>  
                                <CCol sm="12">
                                  <CTextarea label="Notes" 
                                  :disabled="viewOnly"
                                  v-model="trainer.rate_notes"/>
                                </CCol>
                                <CCol sm="12">
                                  <CTextarea label="PO Number" 
                                  :disabled="viewOnly"
                                  v-model="trainer.po_number"/>
                                </CCol>
                              </CRow>
                            </a-collapse-panel>
                          </a-collapse>
                        </CCardBody>
                      </CCard>
                    </CCardBody>

                    <CCardBody v-if="currentStep == 6">
                      <CRow>
                      <CCol sm="12" style="margin-bottom: 8px">
                          Date of Expiry
                        </CCol>
                        <CCol sm="12" style="margin-bottom: 8px">
                          <a-date-picker
                            :disabled-date="disabledExpiryDate"
                            :format="dateFormat"
                            :disabled="viewOnly"
                            v-model="expiryDate"
                          ></a-date-picker>
                        </CCol>
                        <CCol sm="12">
                          <CSelect
                            label="Course Status"
                            :options="courseStatusOptions"
                            :disabled="viewOnly"
                            :value="courseStatus"
                            @update:value="selectCourseStatus"
                          />
                        </CCol>
                        <CCol sm="12">
                          <CTextarea label="Notes" v-model="notes" :disabled="viewOnly" />
                        </CCol>
                        <CCol sm="12">
                          <CTextarea label="Knights Notes Only" v-model="knightsNotesOnly" :disabled="viewOnly" />
                        </CCol>
                        <CCol sm="12">
                          <CTextarea label="Account Notes Only" v-model="accountNotesOnly" :disabled="viewOnly" />
                        </CCol>
                      </CRow>
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
            </CCardBody>


            <CCardFooter>
              <CButton
                v-if="currentStep < steps.length - 1"
                color="info"
                @click="stepNext"
                >Next</CButton
              >

              <CButton
                v-if="(currentStep == steps.length - 1) && (this.routeParams.id < 1) && (loggedInUserRole != 'admin')"
                color="success"
                @click="categorizeBooking('for_approval')"
                :disabled="courseStatus != 'Ready to Book'"
                >Submit for Booking</CButton
              >

              <CButton
                v-if="(currentStep == steps.length - 1) && (this.routeParams.id > 0) && (loggedInUserRole != 'admin') && (status != 'Booked') && (status != 'For Approval')"
                color="success"
                @click="categorizeBooking('for_approval')"
                style="margin-left: 8px"
                :disabled="courseStatus != 'Ready to Book'"
                >Submit for Booking</CButton
              >

              <CButton
                v-if="((currentStep == steps.length - 1) && (this.routeParams.id > 0) && (loggedInUserRole == 'admin') && (status != 'Booked') && (status != 'Rejected') && (status != 'Provisional Booking')) || ((loggedInUserRole == 'admin') && (currentStep == steps.length - 1))"
                color="success"
                @click="bookConfirm"
                style="margin-left: 8px"
                :disabled="courseStatus != 'Ready to Book'"
                >Book</CButton
              >

              <CButton
                v-if="((this.routeParams.id > 0) && (loggedInUserRole == 'admin') && (status == 'Booked'))"
                color="success"
                @click="send_confirmation_emails"
                style="margin-left: 8px"
                >Send Confirmation Emails</CButton
              >

              <CButton
                v-if="(currentStep == steps.length - 1) && (status != 'Booked') && (status != 'For Approval') && (status != 'Cancelled')"
                color="primary"
                @click="categorizeBooking('provisional_booking')"
                style="margin-left: 8px"
                >Provisionally Book</CButton
              >

              <CButton
                v-if="(currentStep > 2) && (status != 'Booked') && (status != 'For Approval') && (status != 'Cancelled')"
                color="warning"
                @click="categorizeBooking('draft')"
                style="margin-left: 8px"
                >Save as Draft</CButton
              >

              <CButton
                v-if="((currentStep == steps.length - 1) && (this.routeParams.id > 0) && (loggedInUserRole == 'admin') && (status == 'For Approval') && (status != 'Booked'))"
                color="warning"
                style="margin-left: 8px"
                @click="bookRejectModal"
                >Reject</CButton
              >

              <CButton
                v-if="((currentStep == steps.length - 1) && (this.routeParams.id > 0) && (status != 'Cancelled') && (status != 'Booked'))"
                color="danger"
                style="margin-left: 8px"
                @click="bookCancelModal"
                >Cancel Booking</CButton
              >

              <CButton
                v-if="currentStep > 0"
                color="dark"
                @click="stepPrev"
                style="margin-left: 8px"
                variant="outline"
                >Back</CButton
              >
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>
    </a-spin>
  </div>
</template>

<script>
  import encryptStorage from "../../controllers/encryptStorage.js";
  import HTTPRequest from "../../controllers/httpRequest.js";
  import Autocomplete from 'vuejs-auto-complete';
  import { mapState } from "vuex";
  import moment from 'moment';
  import _isEmpty from 'lodash/isEmpty';
  import _compact from 'lodash/compact';
  import _unionWith from 'lodash/unionWith';
  import _isEqual from 'lodash/isEqual';
  import ClientForm from "../forms/ClientForm.vue"
  import BookingInformationForm from "../forms/BookingInformationForm.vue"
  import Delegates from "../utils/Delegates.vue"
  import Feedback from "../utils/Feedback.vue"
  import Tentative from "../utils/Tentative.vue"
  import VueGoogleAutocomplete from 'vue-google-autocomplete'

  export default {
    name: "BookingForm",
    components: { Autocomplete, ClientForm, BookingInformationForm, Delegates, Feedback, VueGoogleAutocomplete, Tentative },
    computed: {
      ...mapState(["endPoint"]),
      routeParams() {
        return this.$route.params;
      },
    },
    data() {
      return {
        successToasterMessage: "Success, you have submitted your request!",
        drawerVisible: false,
        viewOnly: false,
        bankHolidays: [],
        nonStandardReason: '',
        loggedInUserId: encryptStorage.getItem('loggedInUserId'),
        dateFormat: "DD-MMM-YYYY",
        authHeaders: {
          Authorization: 'Bearer ' + encryptStorage.getItem("accessToken"),
          "Access-Control-Allow-Origin": "*" 
        },
        steps: [
          {
            title: "Date of Enquiry"
          },
          {
            title: "Client Information"
          },
          {
            title: "Course"
          },
          {
            title: "Venue"
          },
          {
            title: "Preferred Dates"
          },
          {
            title: "Available Dates"
          },
          {
            title: "Info & Notes"
          }
        ],
        additionalEmail: [],
        additionalEmailVisible: false,
        additionalEmailValue: '',
        currentStep: 0,
        bookingDate: moment(new Date(), "DD-MMM-YYYY"),
        client: {},
        course: {},
        courseTypeOptions: ['Standard', 'Non-Standard', 'FOC'],
        courseType: "",
        isRemoteFaciltated: false,
        isNonStandardCourse: false,
        venue: "",
        receivedRiskAssessmentDocuments: false,
        receivedDocumentPolicies: false,
        expiryDate: moment(new Date()).add(8, 'days'),
        courseStatus: "",
        courseStatusOptions: [ "",
          "Awaiting Date from Client", 
          "Awaiting PO Order", 
          "On Hold", 
          "Awaiting Info", 
          "Provisional Booking", 
          "Ready to Book"
        ],
        rejectReasonOptions: [
          "Requested date(s) not available", 
          "Doocumentation Missing", 
          "New Course Query", 
          "General Query"
        ],
        poNumber: "",
        preferredDates: [],
        checkedOfferedDates: [],
        errorMsgs: [],
        knightsNotesOnly: "",
        accountNotesOnly: "",
        notes: "",
        showBookModal: false,
        componentKey: 0,
        showSuccessBookingToaster: false,
        loggedInUserRole: encryptStorage.getItem('loggedInUserRole'),
        status: "",
        fullAddress: {},
        selectedSortTrainer: 'distance',
        rejectReason: "",
        rejectReasonSelect: "", 
        showRejectModal: false,
        showCancelModal: false,
        spinning: false,
        courseScheduleMode: "AD",
        course2: {},
        booked_by: 0,
        monthFilter: "",
        sendEmailsOnBooking: true,
        bookingInformation: {},
        requestor: {},
        clientDrawerKey: 0,
        originalCheckedOfferedDates: [],
        bookingInfoDrawerVisible: false,
        bookingInfoDrawerKey: 0,
        bookingData: {},
        currentTrainers: [],
        availableDates: [],
        delegatesDrawerVisible: false,
        delegatesDrawerKey: 0,
        bookingId: 0,
        feedbackDrawerVisible: false,
        feedbackDrawerKey: 0,
        latestKeys: [],
        tentativeAvailableId: 0,
        tentativeDrawerVisible: false,
        tentativeDrawerKey: 0,
        clients: [],
        clientSearching: false
      };
    }, 
    mounted: async function() {      
      this.fetchHolidays();
      this.initializePage();
    },
    methods: {
      async onClientSearch(searchText) {
        this.clientSearching = true;
        let clients = await HTTPRequest.getRequest(
            this.endPoint, "/api/v1/bookings/find_clients?name=" + searchText,
            null,
            null
        );
        this.clients = clients;
        this.clientSearching = false;
      },
      async resetAcceptedStatus(trainer){
        let available_id = trainer.id
        let availableIndex = 0
        availableIndex = this.availableDates.findIndex((obj => obj.id == available_id));
        this.availableDates[availableIndex].accepted = 'N'

        //Update backend Available record accepted to N
        let availableBody = {
          id: available_id
        };
        let response = await HTTPRequest.postRequest(
          this.endPoint,
          "/api/v1/availables/reset_accepted",
          null,
          availableBody
        );
        if (response == true) {
          this.successToasterMessage = "Success on resetting accepted status";
          this.showSuccessBookingToaster = true;
        }

      },
      tentativeMessages(tentativeAvailableId){
        this.tentativeAvailableId = tentativeAvailableId;
        this.openDrawer('tentative');
      },
      getAddressData(addressData, placeResultData) {
        this.venue = placeResultData.name + ', ' + placeResultData.formatted_address
        let lat = addressData.latitude
        let lng = addressData.longitude
        this.fullAddress = {latitude: lat, longitude: lng};
      },
      async initializePage(){
        if (this.routeParams.id > 0) {
          let draft_event = await HTTPRequest.getRequest(this.endPoint, "/api/v1/bookings/get_draft_event_booking?draft_event_id="+this.routeParams.id, null, null);
          this.bookingDate = moment(new Date(draft_event.enquiry_date)) 
          this.expiryDate = moment(new Date(draft_event.expiry_date))
          this.client = draft_event.client_information.client || {};
          this.client.company_name = draft_event.client_information.company_name || "";
          this.client.company_id = draft_event.client_information.company_id || "";
          this.course = draft_event.course || {};
          this.course2 = draft_event.course2 || {};

          if (this.course) {
            this.course.course_timings = draft_event.course_timings1 || "";
          }
          if (this.course2) {
            this.course2.course_timings = draft_event.course_timings2 || "";
          }

          if (!_isEmpty(this.course2)) this.courseScheduleMode = 'AP'
          this.courseScheduleMode = draft_event.course_schedule_mode;
          this.requestor = draft_event.requestor || {};

          this.courseType = draft_event.course_type
          this.nonStandardReason = draft_event.non_standard_reason
          this.isRemoteFaciltated = draft_event.is_remote_facilitated
          this.preferredDates = draft_event.preferred_dates
          this.booked_by = draft_event.booked_by;
          this.fullAddress = {latitude: draft_event.latitude, longitude: draft_event.longitude}
          this.additionalEmail = _compact(draft_event.additional_email.split(","));
          this.originalCheckedOfferedDates = _compact(draft_event.checked_offered_dates) //For Backup purposes
          this.checkedOfferedDates = _compact(draft_event.checked_offered_dates)
          this.availableDates = draft_event.trainer_data;

          this.venue = draft_event.address
          this.receivedRiskAssessmentDocuments = draft_event.received_risk_assessment_documents
          this.receivedDocumentPolicies = draft_event.received_document_policies
          this.courseStatus = draft_event.course_status
          this.poNumber = draft_event.po_number
          this.knightsNotesOnly = draft_event.knights_notes_only
          this.accountNotesOnly = draft_event.account_notes_only
          this.notes = draft_event.notes
          this.rejectReason = draft_event.reject_reason

          this.getCurrentTrainers();

          switch(draft_event.status) {
            case 'F':
              this.status = "For Approval"
              this.viewOnly = true;
              return;
            case 'D':
              this.status = 'Provisional Booking'
              return;
            case 'B':
              this.status = 'Booked'
              this.viewOnly = true;
              this.retrieveBookingInformation();
              return;
            case 'R':
              this.status = 'Rejected'
              return;
            case 'C':
              this.status = 'Cancelled'
              this.viewOnly = true;
              return;
            case 'U':
              this.status = 'Draft'
              return;
          }
        }
      },
      async onVenueSearch(value){
        let response = await HTTPRequest.getRequest(
          this.endPoint,
          "/api/v1/bookings/search_geocode?query="+value,
          null,
          null
        );
        if (response.result[0].data) {
          this.venue = response.result[0].data.formatted_address
          let lat = response.result[0].data.geometry.location.lat
          let lng = response.result[0].data.geometry.location.lng
          this.fullAddress = {latitude: lat, longitude: lng}
        }
      },
      clearVenue() {
        this.venue = "" 
        this.fullAddress = {}
      },
      removeDate(id){
        //this.availableDates = this.availableDates.filter(item => item.id !== id);
        this.checkedOfferedDates = this.checkedOfferedDates.filter(item => item != id)
      },
      async splitDraft(availableId, formatted_date){
        let splitBody = {
          available_id: availableId,
          draft_event_id: this.routeParams.id
        };
        let response = await HTTPRequest.postRequest(
          this.endPoint,
          "/api/v1/bookings/split_draft",
          null,
          splitBody
        );
        if (response.errors) {
          this.errorMsgs = response.errors
        } else {
          if (response.new_draft_event.id > 0) {
            this.$route.params.id = response.new_draft_event.id
            this.initializePage();
            this.currentStep = 0;
            this.componentKey++;
            this.successToasterMessage = "Success on moving schedule: " + formatted_date;
            this.showSuccessBookingToaster = true;
          }
        }
      },
      async collapsePanelChange(keys) {
        this.latestKeys = keys;
        if (keys.length > 0){
          var response = await HTTPRequest.getRequest(
            this.endPoint,
            "/api/v1/bookings/find_trainer_dates?trainer_ids=" + keys.join(","),
            null,
            null
          );
          this.availableDates = _unionWith(response.json, this.availableDates, this.matcher);
        }
      },
      matcher(a,b) {
        return (a.date == b.date) && (a.user_id == b.user_id)
      },
      showDelegates(bookingId){
        this.bookingId = bookingId;
        this.openDrawer('delegates');
      },
      showFeedback(bookingId){
        this.bookingId = bookingId;
        this.openDrawer('feedback');
      },
      processBookingInfo(bookingData){
        this.bookingData = bookingData;
        this.openDrawer('booking_info');
      },
      receivedFromClientForm(client){
        this.client = client;
        this.$refs.autocomplete.clear();
        this.closeDrawer();
      },
      async retrieveBookingInformation(){
        let booking_information = await HTTPRequest.getRequest(this.endPoint, "/api/v1/bookings/booking_information?draft_event_id="+this.routeParams.id, null, null);
        this.bookingInformation = booking_information.bookings
      },
      receivedFromBookingInformationForm(){
        this.retrieveBookingInformation();
        this.closeDrawer();
      },
      openDrawer(type){
        if (type=='client') {
          this.drawerVisible = true;
          this.clientDrawerKey++;
        } else if (type=='booking_info') {
          this.bookingInfoDrawerVisible = true;
          this.bookingInfoDrawerKey++;  
        } else if (type=='delegates') {
          this.delegatesDrawerVisible = true;
          this.delegatesDrawerKey++;
        } else if (type=='feedback') {
          this.feedbackDrawerVisible = true;
          this.feedbackDrawerKey++;
        } else if (type=='tentative') {
          this.tentativeDrawerVisible = true;
          this.tentativeDrawerKey++;
        }
      },
      closeDrawer(){
        this.bookingInfoDrawerVisible = false;
        this.bookingInfoDrawerKey++;
        this.drawerVisible = false;
        this.clientDrawerKey++;
        this.delegatesDrawerVisible = false;
        this.feedbackDrawerVisible = false;
        this.tentativeDrawerVisible = false;

      },
      clearClient(){
        this.client = {}
        this.additionalEmail = [];
        this.clearRequestor();
      },
      clearRequestor(){
        this.requestor = {}
      },
      additionalEmailHandleClose(removedTag) {
        const tags = this.additionalEmail.filter(tag => tag !== removedTag);
        this.additionalEmail = tags;
      },
      additionalEmailShowInput() {
        this.additionalEmailVisible = true;
        this.$nextTick(function() {
          this.$refs.input.focus();
        });
      },
      additionalEmailHandleInputChange(e) {
        this.additionalEmailValue = e.target.value;
      },
      additionalEmailHandleInputConfirm() {
        let re_email = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

        const additionalEmailValue = this.additionalEmailValue;
        let additionalEmail = this.additionalEmail;

        if (additionalEmailValue && additionalEmail.indexOf(additionalEmailValue) === -1) {
          if (re_email.test(additionalEmailValue)) {
            additionalEmail = [...additionalEmail, additionalEmailValue];
          }
        }
        Object.assign(this, {
          additionalEmail,
          additionalEmailVisible: false,
          additionalEmailValue: '',
        });
      },
      stepDescription(item){
        switch(item.title) {
          case 'Date of Enquiry':
            return this.bookingDate.format("DD-MMM-YYYY");
          case 'Client Information':
            return (!_isEmpty(this.client)) ? this.client.company_name + ' [' + this.client.name + ' ' + this.client.surname + ']' : "";
          case 'Course':
            let course = (!_isEmpty(this.course)) ? this.course.title : "";
            let course2 = (!_isEmpty(this.course2)) ? this.course2.title : "";
            return (course2) ? course + " and " + course2 : course;
          case 'Venue':
            return this.venue;
          case 'Preferred Dates':
            return "";
          case 'Available Dates':
            return "";
          case 'Info & Notes':
            return this.courseStatus;
        }
      },
      filterTrainerDates(date){
        if (this.monthFilter) {
          return (date.substring(0,7) == this.monthFilter);
        } else {
          return true;
        }
      },
      onMonthFilterChange(date, dateString) {
        this.monthFilter = dateString;
      },
      disabledDate(current) {
        return current && current > moment().endOf('day');
      },
      disabledExpiryDate (current) {
        return current <  moment(new Date()).add(7, 'days').endOf('day');
      },
      moment,
      async reject(){
        if (this.rejectReason) {
          var bookingBody = {
            draft_id: this.routeParams.id,
            reject_reason: this.rejectReason
          };
          var draft = await HTTPRequest.postRequest(
            this.endPoint, 
            "/api/v1/bookings/reject_draft",
            null, 
            bookingBody); 
          this.componentKey += 1;
          this.currentStep = 0;
          this.showRejectModal = false;
          this.showSuccessBookingToaster = true;
          this.$router.push("/bookings");
        } else {
          this.errorMsgs.push("Please provide a reason");
        }
      },
      async cancel(){
        if (this.rejectReason) {
          var bookingBody = {
            draft_id: this.routeParams.id,
            reject_reason: this.rejectReason
          };
          var draft = await HTTPRequest.postRequest(
            this.endPoint, 
            "/api/v1/bookings/cancel_draft",
            null, 
            bookingBody); 
          this.componentKey += 1;
          this.currentStep = 0;
          this.showCancelModal = false;
          this.showSuccessBookingToaster = true;
          this.$router.push("/bookings");
        } else {
          this.errorMsgs.push("Please provide a reason");
        }
      },
      async send_confirmation_emails(){
        this.spinning = true;
        if (this.validateActions()) {
          var bookingBody = {
            draft_id: this.routeParams.id
          };

          var draft = await HTTPRequest.postRequest(
            this.endPoint, 
            "/api/v1/bookings/send_confirmation_emails",
            null, 
            bookingBody); 
          this.componentKey += 1;
          this.currentStep = 0;
          this.showBookModal = false;
          this.showSuccessBookingToaster = true;
          this.$router.push("/bookings");
        }
        this.spinning = false;
      },
      stepNext() {
        this.errorMsgs = [];
        var validate = this.validateFields();
        if (validate) {
          this.currentStep++; 
          this.componentKey += 1;
        }
      },
      //async getAdditionalEmails(){
      //  this.spinning = true;
      //  var r = await HTTPRequest.getRequest(
      //    this.endPoint, 
      //    "/api/v1/bookings/find_additional_email?client_id=" + this.client.id,
      //    null, 
      //    null
      //  )

      //  this.additionalEmail = _compact(r.split(","));
      //  this.spinning = false;
      //},
      validateFields(){
        switch(this.currentStep) {
          case 0:
            if (this.bookingDate != null) {
              return true;
            } else {
              this.errorMsgs.push("Booking Date is required");
              return false;
            }
          case 1:
            var re = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
            if (_isEmpty(this.client)) {
              this.errorMsgs.push("Please input or select a client");
              return false;
            } else if (!re.test(this.client.email)) {
              this.errorMsgs.push("Please check your email input");
              return false;
            } else if (!this.client.tel) {
              this.errorMsgs.push("Please check your telephone input");
              return false;
            } else if (!this.client.name) {
              this.errorMsgs.push("Please check your client name");
              return false;
            } else if (!this.client.surname) {
              this.errorMsgs.push("Please check your client surname");
              return false;
            } else if (!this.client.company_name) {
              this.errorMsgs.push("Please check your company name");
              return false;
            } else if (!this.client.company_id) {
              this.errorMsgs.push("Please check your company name");
              return false;
            }
            
            if (!(_isEmpty(this.requestor))) {
              if ((this.requestor.email) && (!re.test(this.requestor.email))) {
                this.errorMsgs.push("Please check your requestor email input");
                return false;
              }
            }
            return true;
          case 2:
            if (this.courseScheduleMode == 'AD') {
              if (_isEmpty(this.course)) {
                this.errorMsgs.push("Course is required");
                return false;
              } else {
                return true;
              }
            } else if (this.courseScheduleMode == 'AP') {
              if (_isEmpty(this.course) && _isEmpty(this.course2)) {
                this.errorMsgs.push("Course is required");
                return false;
              } else {
                return true;
              }
            } else {
              return true;
            }
          case 3:
            if (this.venue) {
              this.getCurrentTrainers();
              return true;
            } else {
              this.errorMsgs.push("Venue is required");
              return false;
            }
          case 4:
            if (this.preferredDates.length > 0) {
              return true;
            } else {
              this.errorMsgs.push("Please select atleast one preferred date");
              return false;
            }
          case 5:
            if (this.checkedOfferedDates.length > 0) {
              return true;
            } else {
              this.errorMsgs.push("Please select atleast one from available date(s)");
              return false;
            }
          case 6:
            return true;                                           

        } 

        this.componentKey++;
      },
      validateActions() {
        this.errorMsgs = [];

        if (this.bookingDate == null) {
          this.errorMsgs.push("Booking Date is required");
        }

        var re = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        if (_isEmpty(this.client)) {
          this.errorMsgs.push("Please input or select a client");
        } else if (!re.test(this.client.email)) {
          this.errorMsgs.push("Please check your email input");
        } else if (!this.client.tel) {
          this.errorMsgs.push("Please check your telephone input");
        } else if (!this.client.name) {
          this.errorMsgs.push("Please check your client name");
        } else if (!this.client.surname) {
          this.errorMsgs.push("Please check your client surname");
        } else if (!this.client.company_name) {
          this.errorMsgs.push("Please check your company name");
        } else if (!this.client.company_id) {
          this.errorMsgs.push("Please check your company name");
        }

        if (this.courseScheduleMode == 'AD') {
          if (_isEmpty(this.course)) {
            this.errorMsgs.push("Course is required");
          } 
        } else if (this.courseScheduleMode == 'AP') {
          if (_isEmpty(this.course) && _isEmpty(this.course2)) {
            this.errorMsgs.push("Course is required");
          } 
        }

        if (!this.venue) {
          this.errorMsgs.push("Venue is required");
        }

        if (!(this.preferredDates.length > 0)) {
          this.errorMsgs.push("Please select atleast one preferred date");
        }

        if (!(this.checkedOfferedDates.length > 0)) {
          this.errorMsgs.push("Please select atleast one from available date(s)");
        }

        if ((this.expiryDate == null) || (this.courseStatus == "")) {
          this.errorMsgs.push("Expiry Date, Course Status are required");
        }
 
        return !(this.errorMsgs.length > 0)
      },
      async stepPrev() {
        this.currentStep--;
      
        //To Retrieve when going to Trainers Availability
        if (this.currentStep == 5) {
          this.getCurrentTrainers();

          this.latestKeys = keys;
          if (keys.length > 0){
            var response = await HTTPRequest.getRequest(
              this.endPoint,
              "/api/v1/bookings/find_trainer_dates?trainer_ids=" + this.latestKeys.join(","),
              null,
              null
            );
            this.availableDates = _unionWith(response.json, this.availableDates, this.matcher);
          }

        }
      },
      bookConfirm() {
        this.showBookModal = true;
      },
      bookRejectModal() {
        this.showRejectModal = true;
      },
      bookCancelModal() {
        this.showCancelModal = true;
      },
      modalCancel() {
        this.showBookModal = false;
        this.showRejectModal = false;
        this.showCancelModal = false;
      },
      findRequestorsEndpoint(searchText) {
        return this.endPoint + "/api/v1/bookings/find_requestors?name=" + searchText;
      },
      autocompleteFormatDisplayRequestor (result) {
        return result.firstname + ' ' + result.surname;
      },
      async onClientSelect(event) {
        let client = await HTTPRequest.getRequest(
            this.endPoint, "/api/v1/users/" + event,
            null,
            null
        );
        this.client = client;
      },
      selectRequestor (group) {
        this.requestor = group.selectedObject;
        this.$refs.autocomplete.clear();
      },
      findCoursesEndpoint(searchText) {
        return this.endPoint + "/api/v1/bookings/find_courses?client_company_id="+this.client.company_id+"&title=" + searchText;
      },
      autocompleteFormatDisplayCourse (result) {
        return result.title;
      },
      selectCourse (group) {
        this.course = group.selectedObject;
        this.$refs.autocomplete.clear();
        this.checkedOfferedDates = []
      },
      selectCourse2 (group) {
        this.course2 = group.selectedObject;
        this.$refs.autocomplete.clear();
        this.checkedOfferedDates = []
      },
      async getCurrentTrainers(){
        this.spinning = true;
        var course_id = this.course.id || 0;
        var course_id_2 = this.course2.id || 0;

        var available_trainers = await HTTPRequest.getRequest(
          this.endPoint,
          "/api/v1/bookings/available_trainers?course_id=" + course_id + "&course_id_2=" + course_id_2 + "&longitude="+ this.fullAddress.longitude + "&latitude="+ this.fullAddress.latitude + "&draft_id=" + this.routeParams.id,
          null,
          null
        );
        this.currentTrainers = available_trainers.trainers
        
        this.spinning = false;
      },
      onExpiryDateChange(date, dateString) {
        this.expiryDate = dateString;
      },
      handleClose(removedTag) {
        const tags = this.preferredDates.filter(tag => tag !== removedTag);
        this.preferredDates = tags;
      },
      onPreferredDateSelect(date, dateString) {
        this.preferredDates.push(dateString);
      },
      selectCourseType(value){
        this.courseType = value;
        this.nonStandardReason = '';
      },
      selectCourseStatus(value){
        this.courseStatus = value;
      },
      selectRejectReason(value){
        this.rejectReasonSelect = value;
        this.rejectReason = value;
      },
      courseTimingChange() {
        this.courseType = 'Non-Standard'
      },
      checkRemoteFacilitated(value) {
        this.isRemoteFaciltated = value;
        if (value == true) {
          this.venue = "Virtual Classroom";
        } else {
          this.venue = null;
        }
      },
      checkSendEmailsOnBooking(value) {
        this.sendEmailsOnBooking = value;
      },
      checkReceivedRiskAssessmentDocuments(value, event) {
        this.receivedRiskAssessmentDocuments = value;
      },
      checkReceivedDocumentPolicies(value, event) {
        this.receivedDocumentPolicies = value;
      },
      checkOfferedDate(value, event) {
        if (value == true) {
          this.checkedOfferedDates.push(event.target.value);
        } else {
          const dates_id = this.checkedOfferedDates.filter(id => id !== event.target.value);
          this.checkedOfferedDates = dates_id;
        }
      },
      getBadge (status) {
        return status === 'For Approval' ? 'info'
          : status === 'Booked' ? 'success'
            : status === 'Cancelled' ? 'danger'
              : status === 'Provisional Booking' ? 'secondary'
                : status === 'Rejected' ? 'warning'
                  : status === 'Draft' ? 'light' : 'primary'
      },
      sortTrainer(option) {
        this.selectedSortTrainer = option;
        if (option == 'distance') {
          this.currentTrainers.sort(function(a, b) {
            var keyA = new Date(a.distance_away_from_venue), keyB = new Date(b.distance_away_from_venue);
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          });
          this.currentTrainers = this.currentTrainers;
        } else if(option == 'rate') {
          this.currentTrainers.sort(function(a, b) {
            var keyA = parseInt(a.full_day_rate), keyB = parseInt(b.full_day_rate);
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          });
          this.currentTrainers = this.currentTrainers;
        } else if(option == 'booking_count') {
          this.currentTrainers.sort(function(a, b) {
            var keyA = new Date(a.booking_count), keyB = new Date(b.booking_count);
            if (keyA < keyB) return 1;
            if (keyA > keyB) return -1;
            return 0;
          });
          this.currentTrainers = this.currentTrainers;
        } else if(option == 'vaccination_status') {
          this.currentTrainers.sort(function(a, b) {
            var keyA = a.is_vaccinated, keyB = b.is_vaccinated;
            if (keyA < keyB) return 1;
            if (keyA > keyB) return -1;
            return 0;
          });
          this.currentTrainers = this.currentTrainers;
        } else if(option == 'remote_rate') {
          this.currentTrainers.sort(function(a, b) {
            var keyA = parseInt(a.half_day_rate), keyB = parseInt(b.half_day_rate);
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          });
          this.currentTrainers = this.currentTrainers;
        } else {

        }
      },
      onStepClick(current){
        if ((this.status == "Booked") || (this.status == "For Approval") || (this.status == "Provisional Booking")) {
          this.currentStep = current;
        }
      },
      onChangeCourseScheduleMode(e) {
        this.courseScheduleMode = e.target.value;
        this.course = {}
        this.course2 = {}
      },
      getDateStyle(current) {
        const style = {};
        let currentIsHoliday = this.bankHolidays.some(holiday => holiday.date === current.format("YYYY-MM-DD"));
        if ((current.weekday() == 0) || (current.weekday() == 6)) {
          style.color = '#f9b115';
        } else if (currentIsHoliday) {
          style.color = '#e55353';
        }
        return style;
      },
      fetchHolidays() {
        let d;
        const endpoint = 'https://www.gov.uk/bank-holidays.json';
        fetch(endpoint)
          .then((response) => response.json())
          .then((data) => d = this.setBankHolidays(data));
      },
      setBankHolidays(data) {
        this.bankHolidays = data["england-and-wales"].events;
      },
      categorizeBooking(type) {
        let hasValidation = true;
        switch(type) {
          case 'for_approval':
            break;
          case 'book':
            break;
          case 'provisional_booking':
            break;
          case 'draft':
            hasValidation = false;
            break;
        }
        this.processBooking(type, hasValidation);
      },
      async processBooking(type, hasValidation){
        this.spinning = true;
        this.errorMsgs = [];

        if (hasValidation && (!this.validateActions())) {
          this.spinning = false;
          return;
        }

        let available_data = this.availableDates.filter(item => this.checkedOfferedDates.includes(String(item.id)));

        let bookingBody = {
          process_type: type,
          available_ids: this.checkedOfferedDates,
          available_data: available_data,
          venue: this.venue,
          latitude: this.fullAddress.latitude,
          longitude: this.fullAddress.longitude,
          course_type: this.courseType,
          non_standard_reason: this.nonStandardReason,
          is_remote_facilitated: this.isRemoteFaciltated,
          preferred_dates: this.preferredDates,
          expiry_date: this.expiryDate,
          course_status: this.courseStatus,
          po_number: this.poNumber,
          knights_notes_only: this.knightsNotesOnly,
          account_notes_only: this.accountNotesOnly,
          notes: this.notes,
          enquiry_date: this.bookingDate,
          received_risk_assessment_documents: this.receivedRiskAssessmentDocuments,
          received_document_policies: this.receivedDocumentPolicies,
          draft_id: this.routeParams.id,
          client: this.client,
          schedule_mode: this.courseScheduleMode,
          course: this.course,
          course2: this.course2,
          additional_email: this.additionalEmail.join(","),
          send_emails: this.sendEmailsOnBooking,
          requestor: this.requestor
        };

        let response = await HTTPRequest.postRequest(this.endPoint, "/api/v1/bookings/process_booking", null, bookingBody);
        this.spinning = false;
        
        if (response.status == 'passed') {
          this.componentKey += 1;
          this.currentStep = 0;
          this.showBookModal = false;
          this.$router.push("/bookings");
          this.showSuccessBookingToaster = true;
        } else {
          if (response.errors.length > 0) {
            this.errorMsgs = response.errors;
          } else {
            this.errorMsgs.push("There was an issue processing your request");
          }
        }
      }
    }
  };
</script>

<style>
.autocomplete__results {
  position: relative;
}
</style>