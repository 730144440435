<template>
  <a-spin tip="Processing..." :spinning="spinning">
    <CCardBody class="p-0">
        <CDataTable
        :items="items"
        :fields="fields"
        table-filter
        >
        </CDataTable>
    </CCardBody>
  </a-spin>
</template>
<script>  
  import encryptStorage from "../../controllers/encryptStorage.js";
  import HTTPRequest from "../../controllers/httpRequest.js";
  import { mapState } from "vuex";

  const fields = [
    { key: 'firstname'},
    { key: 'surname'},
    { key: 'device_used'},
    { key: 'birth_date'},
    { key: 'department'},
    { key: 'gdpr_recording_agree', label: "Agreed to GDPR and Recording"},
    { key: 'full_attendance'},
    { key: 'passed'},
    { key: 'fail_reason'}
  ]

  export default {
    name: "Delegate",
    props: {
      bookingId: Number
    },
    computed: {
      ...mapState(["endPoint"])
    },
    data() {
      return {
        errorMsgs: [],
        loggedInUserId: encryptStorage.getItem('loggedInUserId'),
        authHeaders: {
          Authorization: 'Bearer ' + encryptStorage.getItem("accessToken"),
          "Access-Control-Allow-Origin": "*" 
        },
        spinning: false,
        items: [],
        fields,
      }
    },
    mounted (){
      this.retrieveDelegates();
    },
    methods: {

      async retrieveDelegates(){
        this.spinning = true;

        var response = await HTTPRequest.getRequest(
            this.endPoint, 
            "/api/v1/delegates/all?booking_id="+this.bookingId,
            null, 
            null
        );
        this.items = response.delegates
        this.spinning = false;
      }
    }
  };
</script>
