<template>
  <a-spin tip="Processing..." :spinning="spinning">
    <CCardBody class="p-0">
      <a-comment v-for="f in messages" :key="f.id">
        <a slot="author"> {{ f.user_full_name }}</a>
        <p slot="content">
          {{ f.description }}
        </p>
        <a-tooltip slot="datetime">
          <span>{{ f.formatted_created_date }} </span>
        </a-tooltip>
      </a-comment>

      Message
      <textarea class="form-control mt-2"
        placeholder="Message"
        v-model="message"
      >
      </textarea>

       <div>
          <CButton
            class="mt-2"
            color='primary'
            :block="true"
            @click="submitMessage"
          >
            Submit
          </CButton>
        </div>
    </CCardBody>
  </a-spin>
</template>
<script>  
  import encryptStorage from "../../controllers/encryptStorage.js";
  import HTTPRequest from "../../controllers/httpRequest.js";
  import { mapState } from "vuex";

  export default {
    name: "Message",
    props: {
      tentativeAvailableId: Number
    },
    computed: {
      ...mapState(["endPoint"])
    },
    data() {
      return {
        errorMsgs: [],
        loggedInUserId: encryptStorage.getItem('loggedInUserId'),
        authHeaders: {
          Authorization: 'Bearer ' + encryptStorage.getItem("accessToken"),
          "Access-Control-Allow-Origin": "*" 
        },
        spinning: false,
        messages: [],
        message: ''
      }
    },
    mounted (){
      this.retrieveMessages();
    },
    methods: {
      async retrieveMessages(){
        this.spinning = true;
        let response = await HTTPRequest.getRequest(this.endPoint, "/api/v1/messages/available?available_id="+this.tentativeAvailableId, null, null);
        this.messages = response;
        this.spinning = false;
      },
      async submitMessage(){
        let messageBody = {available_id: this.tentativeAvailableId, message: this.message};
        let response = await HTTPRequest.postRequest(this.endPoint, "/api/v1/messages/create_available", null, messageBody);
        this.retrieveMessages();
        this.message = '';
        this.messages = [];            
        },
    }
  };
</script>


<style scoped>
.ant-comment-inner{
  padding: 0px;
}
</style>